import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';

import $gql from '@/scripts/gql';
import diceQuery from '@/queries/dice.gql';
import globalQuery from '@/queries/global.gql';

import menu from './menu';
import toast from './toast';
import account from './account';
import user from './user';

import jwt from '@/scripts/jwt';

Vue.use(Vuex);

const productSlugs = { core: 'pe', daily: 'home' };

export default new Vuex.Store({
  modules: { menu, toast, account, user },

  state: {
    s3: 'https://crstl-assets.s3-eu-west-2.amazonaws.com/jasmine/',
    view: localStorage.getItem('real-view') === 'true',
    passthrough: '',
    loggedIn: null,
    dice: [],
    dicePromise: null,
    ready: false,

    support: [],
    dashboard: {},
    menuExtra: [],
  },

  mutations: {
    viewToggle(state, val = null) {
      state.view = val !== null ? val : !state.view;
      localStorage.setItem('real-view', state.view);
    },
    setDice(state, dice) {
      state.dice = dice;
    },
    setDicePromise(state, promise) {
      state.dicePromise = promise;
    },
    setGlobal(state, { support, dashboard, peYears, gymYears, danceYears }) {
      state.support = support.map(group => ({ ...group, product: productSlugs[group.product] || group.product }));
      state.menuExtra = { pe: peYears, gym: gymYears, dance: danceYears };
      state.dashboard = dashboard;
    },
  },

  getters: {
    dashUri: ({ account }, getters) => {
      if (getters['user/isHome']) return '/home';
      if (!account.hasRealPe && account.hasBursts && !getters['user/isCreate']) return '/bursts';
      return '/';
    },

    forumUrl: () => process.env.VUE_APP_FORUM,
    lmsUrl: () => process.env.VUE_APP_LMS,
    pfwUrl: () => process.env.VUE_APP_PFW,

    isCreate: ({ user }) => user.kind === 'create',
    isPartner: ({ account }) => account.accountType === 'partner',

    hasRealPe: ({ account }, { isCreate }) => isCreate || account.hasRealPe,
    hasBursts: ({ account }, { isCreate }) => isCreate || account.hasBursts,
    hasRealPeFull: ({ account }, { isCreate }) => !isCreate && account.hasRealPeFull,
    hasRealPeTrial: ({ account }, { isCreate }) => !isCreate && account.hasRealPeTrial,

    hasAccess({ account }) {
      return slug => account.access[slug] || [];
    },
    hasProduct(state, { hasAccess, isCreate }) {
      return slug => isCreate || hasAccess(slug).length;
    },
    hasSection(state, { hasAccess, isCreate }) {
      return (slug, section) => isCreate || hasAccess(slug).includes(section);
    },

    support: (state, getters) => {
      const hasPartner = getters.isCreate || getters.isPartner;

      return state.support
        .filter(group => {
          if (group.slug === 'partner') return hasPartner;
          if (group.product === 'bursts') return getters.hasBursts;
          if (group.product) return getters.hasProduct(group.product);
          return true;
        })
        .map(group => {
          const { children: trash, ...node } = group;
          group.children.unshift({ ...node, title: 'General' });

          return group;
        });
    },
  },

  actions: {
    async init({ state, dispatch, commit }) {
      // ignore auth for password reset / user register / bursts tools page
      if (state.route.meta.auth === false) return;

      const token = jwt.decode();

      if (!token) return dispatch('logOut', { passthrough: true });
      state.loggedIn = true;

      if (token.kind === 'account' || token.kind === 'home') {
        await Promise.all([dispatch('user/fetch'), dispatch('account/self')]);
      } else {
        await dispatch('user/fetch');
      }

      dispatch('fetchGlobal');

      state.ready = true;
    },

    async logIn({ state, commit, dispatch, getters }, user) {
      jwt.set(user.token);
      state.loggedIn = true;

      commit('user/set', user);
      dispatch('fetchGlobal');

      const token = jwt.decode(user.token);
      if (token.kind === 'account' || token.kind === 'home') {
        await dispatch('account/self');
      }

      router.push(state.passthrough || getters.dashUri);
      if (state.passthrough) state.passthrough = '';

      state.ready = true;
    },

    logOut({ state, commit }, { redirect = true, passthrough, toast } = {}) {
      if (state.loggedIn === false) return;

      const path = state.route.path;
      const isLogin = path.startsWith('/login');

      if (passthrough && path && !isLogin && path !== '/') {
        state.passthrough = state.route.fullPath;
      }

      state.loggedIn = false;
      jwt.remove();

      if (state.user.id) commit('user/clear');
      if (state.account.id) commit('account/clear');
      commit('menu/toggle', false);

      if (redirect && !isLogin) {
        router.replace('/login');
      }

      if (toast === 'expired') commit('toast/error', 'Your session has expired. Please log in again.');
    },

    async fetchGlobal({ commit }) {
      await $gql(globalQuery, {}, { schema: 'global' }).then(res => {
        commit('setGlobal', res);
      });
    },

    async loadDice({ state, commit }) {
      if (state.dice.length) return state.dice;
      if (state.dicePromise) return state.dicePromise;

      const site = state.route.params.product;
      const promise = $gql(diceQuery, { site }).then(res => {
        commit('setDice', res.dice);
      });

      commit('setDicePromise', promise);
      return promise;
    },
  },
});
